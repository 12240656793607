@import 'mixins';
@import 'fonts';
@import 'globals';
@import 'colours';


//  CLEAR
html,
body {
    min-width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;

    @extend %font-general;
}

input,
select,
textarea {
    min-height: 10px;
    padding: 10px;
    max-width: 100%;
    box-sizing: border-box;
}

input[type=checkbox],
input[type=radio] {
    transform: scale(1.2);
}


// LOGIN
.login {
    flex: 4;
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    &__image {
        max-width: 100%;
    }

    h4 {
        color: $menuActionColour;
        font-size: 2em;
    }

    form {
        width: 100%;
        max-width: 450px;
        padding: 10px 20px;
        border: solid 1px $menuBorderColour;
        box-sizing: border-box;

        span {
            display: block;
            color: $menuActionColour;
            margin: 3px 0;
        }
    }

    input[type=text],
    input[type=password] {
        width: 100%;
        padding: 4px 10px;
        box-sizing: border-box;
    }

    #btnLogin {
        margin: 5px 0;
        box-sizing: border-box;
    }


    #lblFeedback {
        color: $menuWarnColour;
        text-align: center;
    }
}
.changelog {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    @include display-flex();
    flex-flow: column;
    align-items: center;
    background: mix(black, transparent, 90);
    box-sizing: border-box;
    cursor: pointer;

    &__wrapper {
        @include display-flex();
        flex-flow: column;
        align-items: center;
        background: white;
        border: solid 1px $menuBorderColour;
        width: 100%;
        max-width: 1200px;
        max-height: 90vh;
        padding: 15px;
        margin: auto;
        box-sizing: border-box;
    }

    &__title {
        padding: 15px;
        font-size: 1.3em;
        color: $menuActionColour;
        box-sizing: border-box;
    }

    &__subtitle {
        color: mix($menuBorderColour, black, 40);
    }

    &__body {
        padding: 15px;
        margin-top: 10px;
        overflow-y: auto;
        box-sizing: border-box;
    }
}



// ADMIN
.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: $menuLightColour;
    height: 100vh;
    color: $menuWarnColour;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 400;
    z-index: 999;


    h3 {
        color: $menuActionColour;
        text-transform: none;
    }

    p {
        color: $menuColour;
        font-size: 0.8em;
        text-transform: none;
    }

    &.hide {
        display: none;
    }
}
.header-bar {
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin: 10px 0;
    box-sizing: border-box;

    &__title {
        color: $menuActionColour;
        font-size: 1.8em;
        font-weight: 700;

        &.alt {
            color: $menuWarnColour;
        }
    }

    &__subtitle {
        color: $menuColour;
        text-align: center;
    }
}
.action-bar {
    @include display-flex();
    justify-content: flex-start;

    .style-button {
        width: max-content;
    }
}
.filter-bar {
    @include display-flex();
    flex-flow: column;
    padding: 10px;
    box-sizing: border-box;
    

    @media #{$size-medium} {
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
    }
}
//new year
.content-wrapper.new-year-wrapper {
    justify-content: center;
    align-items: center;
}
.content.new-year {
    @include display-flex();
    flex-flow: column;
    align-items: center;
    margin: 0 auto;

    .year {
        input {
            min-width: 250px;
        }
    }

    .prices {
        @include display-flex();
        flex-flow: column;

        h3 {
            font-weight: 700;
            color: $menuFontColour;
            padding: 10px;
            box-sizing: border-box;
        }

        .list {
            display: grid;
            grid-template-columns: 200px 1fr;
            grid-gap: 5px;
            align-items: center;

        }
    }

}

//resend invoices
.content.resend-invoices {
    .header-bar__subtitle {
        font-size: 0.8em;
        max-width: 800px;
        margin: 0 auto;
    }

    .actions {
        @include display-flex();
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .outstanding {
        @include display-flex();
        flex-flow: column;
        max-width: 1200px;
        margin: 0 auto;

        .heads-up {
            @include display-flex();
            flex-flow: row wrap;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;

            h3 {
                padding-right: 15px;
                color: $menuFontColour;
                font-weight: 700;
                box-sizing: border-box;
            }

            .status {
                color: $menuWarnColour;
                font-size: 0.9em;
                font-style: italic;
            }

        }

        .list {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 5px;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;

            .sponsor-invoice {
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                align-items: center;
                grid-gap: 5px;
                width: 100%;
                padding-bottom: 15px;
                margin-bottom: 5px;
                border-bottom: solid 1px $menuBorderColour;
                box-sizing: border-box;

                &:last-child {
                    border-bottom: none;
                }

                form {
                    @include display-flex();
                    justify-content: flex-start;
                    align-items: center;

                    @media #{$size-large} {
                        justify-content: center;
                    }
                }

                @media #{$size-large} {
                    grid-template-columns: 1fr 100px 100px 100px;
                }

                &__type {
                    color: $menuColour;
                    font-size: 0.8em;

                    @media #{$size-large} {
                        text-align: right;
                    }
                }
                &__send {
                    background: transparent;
                    padding: 0;
                    margin: 0;
                    color: $menuActionColour;
                    font-size: 0.8em;
                    text-align: left;
                    border: none;
                    outline: none;
                    user-select: none;
                    cursor: pointer;

                    @media #{$size-large} {
                        text-align: center;
                    }

                    &.disabled {
                        color: grey;
                        opacity: 0.5;
                        pointer-events: none;
                        user-select: none;
                    }
                }
                &__email {
                    color: $menuActionColour;
                    font-size: 0.8em;
                }
            }
        }

    }

}


//PHOTOGRAPHER
.select-year {
    @include display-flex();
    text-align: center;
    font-weight: 400;
    box-sizing: border-box;

    input[type=submit] {
        background: transparent;
        color: $menuFontColour;
        font-size: 1em;
        border: none;
        cursor: pointer;
        user-select: none;
        outline: none;

        &:hover {
            color: $menuColourActive;
        }
    }

    &.selected {
        background: transparent !important;
        cursor: default;

        input[type=submit] {
            color: mix($menuColour, transparent, 50);
            pointer-events: none;
        }
    }
}
.sorry-nothing {
    flex: 1;
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.5em;
    color: $menuFontColour;
}
.photographer-data {
    .new {
        display: none;
        max-height: 100vh;
        padding: 5px;
        overflow-y: auto;
        text-align: center;
        box-sizing: border-box;

        @media #{$size-large} {
            display: block;
        }

        &__title {
            color: $menuWarnColour;
            text-align: center;
        }

        .col-item {
            margin-bottom: 10px;
            border-bottom: solid 1px $menuBorderColour;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.mark {
    background: white;
    color: $menuWarnColour;
    font-size: 1.05em;
    cursor: pointer;
    border: none;
    outline: none;
    user-select: none;
}


// handle irritating email block
.photographer.email.block {
    @include display-flex();
    flex-flow: column;

    .block__group {
        @include display-flex();
        flex-flow: column;

        textarea {
            min-height: 100px;
            resize: vertical;

            @media #{$size-large} {
                min-height: 10px;
            }
        }

        input[type=submit] {
            width: max-content;
            width: -moz-max-content;
            margin-left: 0;
        }
    }
}


/***********************/
/*      SPONSOR        */
/***********************/
.select-sponsor {
    margin-bottom: 25px;

    select {
        width: 100%;
        max-width: 350px;
    }
}
.sponsor-content {
    @include display-flex();
    flex-flow: column;
    width: 100%;
    @extend %max-content;

    select {
        width: 350px;
        max-width: 100%;
    }

    .pagination {
        @include display-flex();
        flex-flow: column;
        align-items: center;
        margin: 15px 0;
        padding: 10px 0;
        font-size: 1.2em;
        border-top: solid 1px $menuBorderColour;
        border-bottom: solid 1px $menuBorderColour;

        @media #{$size-medium} {
            flex-flow: row wrap;
            justify-content: center;
        }

        &__title {
            color: $menuActionColour;
        }

        &__item {
            border-right: solid 1px $menuBorderColour;

            @media #{$size-medium} {
                &:last-child {
                    border-right: none;
                }
            }

            &.active .submit {
                color: black;
                pointer-events: none;
                cursor: default;
            }

            .submit {
                background: transparent;
                padding: 0 5px;
                font-size: 1.2em;
                font-weight: 700;
                color: $tableRowActiveColour;
                cursor: pointer;
                border: none;

                @media #{$size-medium} {
                    font-size: 1.1em;                    
                    transition: ease all 0.3s;
                    &:hover {
                        color: $menuActionColour;
                    }
                }


                

                
            }
        }
    }

    .sponsor-details {
        @media #{$size-medium} {
            max-width: 50vw;
        }
        
        input {
            width: 100%;
            box-sizing: border-box;

            @media #{$size-large} {
                max-width: 450px;
            }
        }
    }

    .sponsor-container {
        position: relative;

        .block__header {
            @media #{$size-large} {
                max-width: calc(70vw - 350px);
            }
        }

        .image {
            background: white;
            width: max-content;
            width: -moz-max-content;
            max-width: 100%;
            box-sizing: border-box;
            z-index: 2;

            @media #{$size-large} {
                position: absolute;
                right: 20px;
                // float: right;
                width: 40vw;
                max-width: 640px;
                border: solid 1px $menuBorderColour;
            }

            &__details {
                width: inherit;
                max-width: inherit;

                @media #{$size-large} {
                    max-width: inherit;
                }

                &__name {
                    color: $menuColour;
                    font-style: italic;
                    text-align: center;
                }

                img {
                    padding: 10px;
                    width: inherit;
                    max-width: inherit;
                    box-sizing: border-box;

                    @media #{$size-large} {
                        max-width: inherit;
                    }
                }
            }

            &__controls {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-content: center;
                align-items: center;
                grid-gap: 5px;
                padding: 0 10px;
                box-sizing: border-box;

                & > * {
                    margin: 0 auto;
                }

                @media #{$size-large} {
                    grid-template-columns: 1fr
                }
                @media #{$size-wide} {
                    grid-template-columns: repeat(2, 1fr);
                }


                &.sponsor {
                    grid-template-columns: 1fr;

                    a {
                        display: block;
                    }
                }

                .style-button {
                    text-align: center;
                    width: 100%;

                    @media #{$size-medium} {
                        width: 200px;
                    }
                }
            }

            &__rows {
                padding: 10px;
                box-sizing: border-box;

                .group {
                    display: grid;
                    grid-template-columns: 120px 1fr;
                    grid-gap: 3px;
                    background: mix($menuBorderColour, white, 40);
                    margin-bottom: 3px;
                    box-sizing: border-box;
                }

                .title {
                    background: white;
                    font-weight: 700;
                    padding: 3px;
                    color: $menuActionColour;
                }

                .val {
                    padding: 3px;
                    box-sizing: border-box;
                }
            }
        }


        .status {
            .color {
                color: $menuActionColour;
                font-weight: 700;
            }


            .detail {
                margin-bottom: 10px;

                &.info {
                    position: relative;
                    padding: 10px 0;
                    font-size: 0.8em;
                    
                    @media #{$size-large} {
                        max-width: 450px;
                    }
                    
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 220px;
                        content: '';
                        border-bottom: solid 1px $menuBorderColour;
                    }
                }
            }
        }


        .names {

            
            &.sponsor {
                padding-top: 5px;
                margin-top: 10px;
            }
            
            // &__header {
            //     max-width: unset;
            //     @media #{$size-large} {
            //         max-width: 35vw;
            //     }
            //     @media #{$size-wide} {
            //         max-width: 45vw;
            //     }
            // }

            &__group {

                &__add {
                    width: max-content;
                    width: -moz-max-content;
                    color: $menuWarnColour;
                    font-size: 1.1em;
                    cursor: pointer;
                }

                .name-item {
                    @include display-flex();
                    align-items: center;
                    margin-bottom: 5px;

                    .move-controls {
                        display: block;
                        padding-right: 10px;
                        color: $menuActionColour;
                        cursor: pointer;

                        &__mobile {
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-gap: 3px;
                            font-size: 0.8em;
                            pointer-events: none;

                            i {
                                pointer-events: all;
                            }
                            @media #{$size-large} {
                                display: none;
                            }
                        }
                        &__desktop {
                            display: none;
                            @media #{$size-large} {
                                display: block;
                            }
                        }
                    }

                    .delete {
                        flex: 0;
                        @extend .style-button;
                        @extend .style-button--alt;
                        padding: 7px 10px;
                        margin: 0;
                    }

                    input[type=text] {
                        flex: 1;
                        width: 100%;

                        @media #{$size-medium} {
                            flex: 0;
                            width: 350px;
                        }
                    }
                }
            }
        }

        .email {
            @include display-flex();
            flex-flow: column;

            textarea {
                width: 100%;
                min-height: 80px;
                max-height: 450px;
                box-sizing: border-box;
                resize: vertical;
            }

            .style-button {
                width: max-content;
                width: -moz-max-content;
            }
        }

        .save {
            text-align: center;
            margin: 0 auto;

            .style-button {
                width: max-content;
                width: -moz-max-content;
                max-width: 500px;
                box-sizing: border-box;
                margin: 0 auto;

                @media #{$size-large} {
                    width: 100%;
                }
            }
        }
    }

}

.sponsor-content.saved {
    flex: 1;
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}


//preview
.sponsor-preview {
    @include display-flex();
    flex-flow: column;
    align-items: center;
    box-sizing: border-box;

    .title-header {
        @include display-flex();
        flex-flow: column;
        align-items: center;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        
        &__title {
            font-size: 1.5em;
            font-weight: 700;
            text-align: center;
        }

        .style-button {
            display: block;
            min-width: unset;
            width: inherit;
            max-width: 100vw;
            text-align: center;
            box-sizing: border-box;
        }

        @media #{$size-medium} {
            display: grid;
            grid-template-columns: 150px 1fr 150px;
        }
    }

    .page {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
        width:  100vw;
        max-width: 1200px;
        padding: 10px;
        margin: 10px auto;
        border: solid 1px $menuBorderColour;
        box-sizing: border-box;

        &__image {
            width: 100%;
            height: auto;
            border: solid 2px black;
            box-sizing: border-box;
            user-select: none;
            pointer-events: none;

            &.one-sixth { }
            &.one-third {
                grid-column: span 2;
            }
            &.two-thirds {
                grid-column: span 2;
            }
            &.full {
                grid-column: span 2;
            }

            &.owned {
                border-color: blue;
            }
        }

        @media #{$size-medium} {
            grid-gap: 10px;
            padding: 15px;
        }
        @media #{$size-large} {
            grid-gap: 15px;
            padding: 30px;
        }

    }

    .spoilers {
        padding: 10px;
        margin-bottom: 30px;
        text-align: center;
    }
}


/******************************************************************************/
/*                                  SOLICITOR                                 */
/******************************************************************************/
.approved {
    color: $successColour;
    font-size: 1.4em;
    margin-left: 10px;
}

.solicitor-data {

    // fix-me-ups
    .block.solicitor {
        padding: 0 20px;
        box-sizing: border-box;
    }
    .switch-sponsor {
        @include display-flex();
        flex-flow: column;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .new-ad {
        @include display-flex();
        flex-flow: column;
        width: 550px;
        max-width: 100%;
        padding: 0 10px;
        margin-top: 10px;
        box-sizing: border-box;

        .style-button {
            max-width: max-content;
        }
    }


    .sponsor-top {
        display: grid;
        grid-template-columns: 1fr;
        margin: 10px;
        margin-bottom: 10px;
        border-bottom: solid 1px $menuBorderColour;
        box-sizing: border-box;

        @media #{$size-large} {
            grid-template-columns: repeat(2, 1fr);
        }

        &__details {
            .sponsor-name {
                padding: 10px;
                box-sizing: border-box;
            }

            .assignment {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 5px;
                justify-content: center;
                margin: 10px;
                margin-top: 25px;
                box-sizing: border-box;
        
        
                @media #{$size-medium} {
                    grid-template-columns: 250px 1fr;
                }
        
                .title {
                    font-weight: 700;
                    color: $menuActionColour;
        
                    &.warn {
                        color: $menuWarnColour;
                    }
                }
        
            }
        }


        &__image {
            @include display-flex();
            justify-content: flex-end; 
            align-items: flex-start;           
            max-width: 100%;
            padding: 20px;
            box-sizing: border-box;
    
            img {
                max-width: inherit;
                height: auto;

                @media #{$size-large} {
                    width: auto;
                    max-width: 550px;
                    max-height: inherit;
                }
            }
        }
    }



    

    .details {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        margin: 5px;
        margin-bottom: 20px;
        box-sizing: border-box;
        clear: both;

        @media #{$size-medium} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{$size-large} {
            grid-template-columns: repeat(3, 1fr);
        }

        .group {
            @include display-flex();
            flex-flow: column;
            padding: 10px;
            box-sizing: border-box;
            border-bottom: solid 1px mix(white, $menuBorderColour, 50);

            .title {
                font-weight: 700;
                color: $menuActionColour;
                margin-bottom: 5px;
            }


            .window {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 10px;

                .past {
                    background: $menuLightColour;
                    padding: 10px;
                    box-sizing: border-box;

                    &__year {
                        color: $menuActionColour;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    &__image {
                        float: right;
                        max-width: 150px;
                        min-height: 100px;

                        img {
                            max-width: inherit;
                            height: auto;
                        }
                    }
                }
            }

        }
        
    }

    .decisions {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        justify-content: center;
        padding: 10px;
        margin: 10px;
        margin-top: 0;
        margin-bottom: 35px;
        box-sizing: border-box;
        border: solid 1px $menuBorderColour;

        & > h3 {
            font-size: 1.4em;
            margin-left: 5px;
        }


        .ad-sizes {
            @include display-flex();
            flex-flow: column;
            padding-bottom: 20px;
            margin-bottom: 10px;
            border-bottom: solid 1px $menuBorderColour;

            .title {
                padding: 5px;
                font-size: 1.1em;
                font-weight: 700;
                color: $menuActionColour;
                box-sizing: border-box;
            }

            .pricing {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 5px;
                grid-row-gap: 10px;
                width: 100%;
                max-width: 500px;
                padding: 10px;
                margin: 10px 0;
                border: solid 1px $menuBorderColour;
                border-radius: 10px;
                box-sizing: border-box;

                h4 {
                    padding: 0;
                    margin: 0;
                }
            }

            .ad-size {
                width: 100%;
                max-width: 500px;
                margin-bottom: 10px;
            }
        }


        .statuses {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;

            @media #{$size-medium} {
                grid-template-columns: repeat(2, 1fr);
            }

            .title {
                padding: 5px;
                font-size: 1.1em;
                font-weight: 700;
                color: $menuActionColour;
                box-sizing: border-box;
                
                @media #{$size-medium} {
                    grid-column: span 2;
                }
            }

            .status {
                @include display-flex;
                flex-flow: column;
                align-items: center;
                padding: 5px;
                border-bottom: solid 1px $menuBorderColour;
                box-sizing: border-box;

                .button-group {
                    position: relative;
                    cursor: pointer;

                    &.agreed {
                        $colour: #0bad26;
                        .button {
                            background: $colour;
                            border-color: $colour;
                            &:hover, &:active, &:focus { background: $colour !important; color: white; border-color: $colour; }
                        }
                        .selected { 
                            border-color: $colour;
                            color: $colour;
                        }
                    }
                    &.pending {
                        $colour: #ad8f0b;
                        .button {
                            background: $colour;
                            border-color: $colour;
                            &:hover, &:active, &:focus { background: $colour !important; color: white; border-color: $colour; }
                        }
                        .selected { 
                            border-color: $colour;
                            color: $colour;
                        }
                    }
                    &.declined {
                        $colour: #ad0b19;
                        .button {
                            background: $colour;
                            border-color: $colour;
                            &:hover, &:active, &:focus { background: $colour !important; color: white; border-color: $colour; }
                        }
                        .selected { 
                            border-color: $colour;
                            color: $colour;
                        }
                    }
                    &.no-contact {
                        $colour: #291c4d;
                        .button {
                            background: $colour;
                            border-color: $colour;
                            &:hover, &:active, &:focus { background: $colour !important; color: white; border-color: $colour; }
                        }
                        .selected { 
                            border-color: $colour;
                            color: $colour;
                        }
                    }

                    .button {
                        min-width: 250px;
                        text-align: center;

                    }

                    .selected {
                        position: absolute;
                        left: -35px;
                        top: 0;
                        bottom: 0;
                        display: none;
                        background: white;
                        min-width: 35px;
                        margin: 10px 0;
                        padding: 8px;
                        box-sizing: border-box;
                        border: solid 1px;
                        border-right-width: 0;
                        border-radius: 50% 0 0 50%;

                        &::after {
                            position: relative;
                            top: -4px;
                            left: 3px;
                            content: '✔';
                            font-size: 1.3em;
                        }
                    }


                    input[type=radio] {
                        display: none;
                    }

                    // do check
                    input[type=radio]:checked {
                        & + .selected { display: block; }
                    }
                }

            }
        }
    }

    .no-contact-group {
        margin: 10px 0;
        padding: 10px;
        padding-bottom: 20px;
        box-sizing: border-box;
        border-bottom: solid 1px $menuBorderColour;

        &.hide {
            display: none;
        }

        .title {
            font-size: 1.1em;
            font-weight: 700;
            color: $menuActionColour;
            box-sizing: border-box;
        }

        textarea {
            width: 100%;
            max-width: 650px;
            min-height: 100px;
            resize: vertical;
            box-sizing: border-box;
        }
    }

    .submission {
        @include display-flex();
        flex-flow: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        margin-top: 15px;

        .style-button {
            width: 100%;
            max-width: 300px;
            text-align: center;

            &.disabled {
                background: $menuBorderColour;
                border-color: $menuBorderColour;
                color: mix($menuBorderColour, $menuFontColour, 50);
                pointer-events: none;
            }
        }

        p {
            color: $menuFontColour;
            box-sizing: border-box;
        }
    }


}