@import 'mixins';
@import 'fonts';
@import 'globals';
@import 'colours';

.sponsor__select{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    .select__label{
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 10px;
    }

    .select__sponsor{
        width: 200px;
    }
}

.data{
    margin-top: 5px;
    margin-bottom: 5px;

    &--success{
        color: #028a02;
    }

    &--error{
        color: #ff0000;
    }
}

.sponsor__details,
.sponsor__subdetails{
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 20px;
    border-bottom: solid black 1px;

    @include display-flex();
}

.sponsor__details{
    flex-flow: column nowrap;
    align-content: flex-start;

    .details__group{
        @include display-flex();
        flex-flow: row wrap;
        margin-bottom: 10px;

        .group__data{
            margin-left: 20px;
        }
    }
}

.sponsor__subdetails{
    flex-flow: row wrap;
    // justify-content: space-around;
    //align-content: center;

    @media #{$size-medium} {
        flex-flow: column nowrap;
    }

    .subdetails__half{
        @include display-flex();
        flex-flow: column nowrap;
        justify-content: space-between;

        width: 50%;

        @media #{$size-medium} {
            flex-flow: row nowrap;
            width: 100%;
        }
    }

    .subdetails__group{
        width: 33%;
        margin-bottom: 10px;
    }
}

.noSponsors{
    @include display-flex();
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    margin-top: 10em;
    


    .noSponsors__header{   
        color: $menuActionColour;
        font-weight: bold;
    }
}



.group__header{
    color: $menuActionColour;
    font-weight: bold;
}

.sponsor__decision{
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 20px;

    @include display-flex();
    flex-flow: column nowrap;
    justify-content: space-between;

    @media #{$size-medium}{
        flex-flow: row nowrap;
    }
}

.decision__adsize{
    width: 100%;

    @media #{$size-medium}{
        width: 33%;
    }

    .adsize__prices, .adsize__invoice{
        margin-top: 10px;

        @media #{$size-medium}{
            margin-top: 0px;
        }
    }

    .adsize__prices{

        table{
            border-spacing: 10px 5px;

            th{
                text-align: start;
            }
        }
    }
}

.decision__buttons{
    width: 100%;

    @include display-flex();
    flex-flow: column nowrap;

    .buttons__half{
        @include display-flex();
        flex-flow: column nowrap;

        @media #{$size-medium}{
            flex-flow: row nowrap;
        }
    }

    .buttons__half:nth-child(2){
        margin-top: 15px;
    }

    .buttons__group{
        width: 100%;
        margin-top: 10px;

        .info{
            margin-bottom: 10px;
        }

        .button{

            width: 100%;
            height: 40px;
            border-radius: 5px;
            border:none;

            color: white;
            font-weight: bold;
            font-size: 1em;

            &:hover{
                cursor: pointer;
            }

            @media #{$size-medium}{
                width: 125px;
                font-size: .9em;
            }

            @media #{$size-large}{
                width: 200px;
                font-size: 1em;
            }

            &--agreed{
                background-color: #00ff00;

                &:disabled{
                    background-color: #666666;
                    cursor: not-allowed;
                }
            }

            &--pending{
                background-color: #ff9900;
            }

            &--declined{
                background-color: #ff0000;
            }

            &--nocontact{
                background-color: #000000;
            }

            
        }

        @media #{$size-medium}{
            width:50%;
            margin-top: 0px;
            margin-left: 5px;
            margin-right: 5px;
        }

        @media #{$size-large}{
            margin-left: 0px;
            margin-right: 0px;
        }

        
    }

    .buttons__group:last-child{
        margin-bottom: 10px;

        @media #{$size-medium}{
            margin-bottom: 0px;
        }
    }

    @media #{$size-medium}{
        width:66.5%
    }
    
}

.nocontact__hidden{
    margin-top: 10px;
    display:none;

    .nocontact__notes{
        width: 90%;
        height: 50px;
    }
}
