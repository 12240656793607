@import 'mixins';
@import 'colours';

.popup-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @include display-flex();
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.26);
    z-index: 99;
    opacity: 1;
    pointer-events: all;
    transition: opacity ease 0.3s;

    &.hide {
        opacity: 0;
        pointer-events: none;
    }
}

.popup {
    @include display-flex();
    flex-flow: column;
    background: white;
    min-width: 300px;
    margin-top: 15vh;
    border: solid 1px grey;

    &__header {
        @include display-flex();
        justify-content: space-between;
        align-items: center;
        background: $menuActionColour;
        padding: 0 10px;
        color: white;
        font-size: 1.2em;

        &__title {
            flex: 1;
        }

        &__close {
            width: max-content;
            max-width: 50px;
            margin-right: 0 !important;
            text-align: center;
        }
    }
    &__body {
        flex: 1;
        padding: 15px;

        .detail {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 5px;

            .row {
                @include display-flex();
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;

                select {
                    flex: 1;
                    width: 100%;
                }

                .delete {
                    flex-shrink: 0;
                    background: $mainColour;
                    padding: 2px 5px;
                    margin: 3px;
                    min-width: 10px;
                    color: white;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                }

                &:first-child {
                    .delete { display: none; }
                }
            }


            .add {
                grid-column: span 2;
                text-align: right;
                justify-self: flex-end;
                color: $mainColour;
                cursor: pointer;
            }
        }

        &.hide {
            display: none;
        }
        
    }
    &__submit {
        margin: 10px auto;
    }
}