@import 'mixins';
@import 'colours';


.content-404 {
    flex: 5;
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $menuFontColour;
    font-size: 1.2em;

    .redButton {
        padding: 4px 10px;
        cursor: pointer;
    }
}