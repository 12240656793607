@import 'mixins';
@import 'fonts';
@import 'globals';
@import 'colours';

.header {
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    grid-template-rows: 60px 1fr;
    align-items: center;
    background: white;
    border-bottom: solid 1px $menuBorderColour;
    @extend %font-general;

    @media #{$size-large} {
        @include display-flex();
        flex-flow: row wrap;
    }

    &__image {
        max-width: 100%;
        max-height: 60px;
        object-fit: contain;
        padding: 4px 10px;
        margin: 0 auto;
        box-sizing: border-box;

        @media #{$size-large} {
            margin: 0;
        }
    }

    &__title {
        @include display-flex();
        justify-content: center;
        padding: 5px;
        font-size: 1.3em;
    }

    &__content {
        grid-column: span 3;
        @include display-flex();
        flex-flow: column;
        background: white;
        width: 100%;
        height: 100%;
        color: $menuFontColour;
        border-top: solid 1px $menuBorderColour;
        border-bottom: solid 1px $menuBorderColour;

        @media #{$size-large} {
            flex: 1;
            grid-column: unset;
            @include display-flex();
            flex-flow: row-reverse;
            width: unset;
            min-height: 60px;
            border-top: none;
            border-bottom: none;
        }

        .views {
            flex: 1;
            @include display-flex();
            flex-flow: column;
            align-items: center;
            width: 100%;

            @media #{$size-large} {
                flex-flow: row;
            }

            &__user {
                @include display-flex();
                justify-content: center;
                background: linear-gradient(mix($menuColour, $menuColourActive, 20), $menuColourActive);
                width: 100%;
                padding: 10px 5px;
                color: white;
                font-size: 1.3em;
                border-bottom: solid 1px $menuBorderColour;
                box-sizing: border-box;

                @media #{$size-large} {
                    width: unset;
                    height: 100%;
                    align-items: center;
                }
            }


            
            &__content {
                flex: 1;
                @include display-flex();
                flex-flow: column;
                align-items: center;
                width: 100%;

                @media #{$size-large} {
                    justify-content: center;
                }


                .title {
                    height: 15px;
                    margin-top: 25px;
                    color: $menuWarnColour;
                    font-size: 0.7em;
                    letter-spacing: 0.15em;
                    font-weight: 700;

                    @media #{$size-large} {
                        margin-top: 5px;
                        margin-bottom: -5px;
                        font-size: 0.6em;
                        font-weight: 400;
                    }
                }

                .items {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 5px;
                    width: 100%;
                    padding: 5px 15px;
                    box-sizing: border-box;

                    @media #{$size-large} {
                        @include display-flex();
                        justify-content: center;
                        padding: 5px;
                    }
    
                    .item {
                        background: white;
                        width: 100%;
                        padding: 15px 0;
                        color: $menuColour;
                        font-size: 1.4em;
                        @extend %font-general;
                        border: none;
                        border-top: solid 1px $menuBorderColour;
                        outline: none;
                        user-select: none;

                        a {
                            color: inherit;
                            text-decoration: none;
                        }

                        &.active {
                            color: $mainColour;
                        }

                        @media #{$size-large} {
                            width: unset;
                            padding: 5px;
                            font-size: 1.2em;
                            border-top: none;
                            cursor: pointer;

                            &:hover {
                                color: $menuColourActive;
                            }
                        }
    
                        &:first-child {
                            border-top: none;
                        }
    
                    }
                }
            }

            
        }

        .login-info {
            @include display-flex();
            align-items: flex-end;
            border-bottom: solid 1px $menuBorderColour;

            @media #{$size-large} {
                border-bottom: none;
            }

            &__container {
                @include display-flex();
                width: 100%;
                border-top: solid 1px $menuBorderColour;

                @media #{$size-large} {
                    border-top: none;
                }
            }

            &__user {
                padding: 10px;
                margin: 0 auto;
                color: $menuActionColour;
                font-size: 1.2em;
                font-weight: 700;
                text-align: center;
                &:empty {
                    padding: 0;
                    margin: 0;
                }

                @media #{$size-large} {
                    @include display-flex();
                    justify-content: center;
                    align-items: center;
                }
            }

            &__login,
            &__logout {
                flex-shrink: 0;
                @include display-flex();
                flex-flow: column;
                background: mix(white, $menuBorderColour, 40);
                padding: 0;
                margin: 0;
                font-size: 1.3em;
                border: none;
                border-left: solid 1px $menuBorderColour;

                a {
                    flex: 1;
                    @include display-flex();
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    color: $menuWarnColour;
                    text-decoration: none;
                    box-sizing: border-box;
                }
            }
        }

        // IF login is first child, do some manipulation to make it show at the top
        & > *:first-child {
            &.login-info {
                align-items: flex-start;

                @media #{$size-large} {
                    align-items: unset;
                }
                .login-info__login {
                    width: 100%;
                    border-left: none;

                    a {
                        width: 100vw;
                        @media #{$size-large} {
                            width: max-content;
                        }
                    }
                }
            }
        }
    }

    @keyframes rotate-bars {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(90deg);
        }
    }

    .collapser {
        @include display-flex();
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 60px;
        border-right: solid 1px $menuBorderColour;
        color: $menuColourActive;

        &__icon {
            font-size: 1.7em;
            font-weight: normal;
            transform: rotate(0deg);
        }

        &__text {
            height: 16px;
            font-weight: 700;
            font-size: 0.7em;
            transition: color ease 0.4s;           
        }

        @media #{$size-large} {
            display: none;
        }
    }

    &.open {
        min-height: 100vh;

        @media #{$size-large} {
            min-height: unset;
        }

        .collapser {
            &__icon {
                color: mix($menuColour, $menuBorderColour, 70);
                animation: rotate-bars 0.3s;
                transform: rotate(90deg);
            }
            &__text {
                color: $menuColour;  
            }
        }
    }
}