@import 'mixins';
@import 'fonts';
@import 'globals';
@import 'colours';

$menuLightColour: mix($menuBorderColour, white, 50);

nav.sub-nav {
    background: $menuLightColour;
    border-top: solid 1px white;
    border-bottom: solid 1px $menuBorderColour;
    @extend %font-general;
    z-index: 85;

    #sub-nav-toggle {
        @media #{$size-large} {
            display: none;
        }
    }

    .title {
        width: 100%;
        padding: 5px;
        margin: 0 auto;
        font-size: 1.2em;
        text-align: center;
        color: $menuColourActive;
        box-sizing: border-box;
    }

    .items {
        @include display-flex();
        flex-flow: column;
        justify-content: center;
        padding: 0;
        margin: 0;

        .current {
            color: $menuActionColour;
        }

        &.hide {
            display: none;
        }

        @media #{$size-medium} {
            flex-flow: row;
        }

        li {
            position: relative;
            @include display-flex();
            justify-content: center;
            align-items: center;
            color: $menuFontColour;
            font-size: 1.05em;
            list-style: none;
            font-weight: 700;
            cursor: pointer;

            &.highlight {
                color: $menuWarnColour;
            }

            a,
            span {
                padding: 10px 2vw;
                color: inherit;
                text-decoration: none;
                box-sizing: border-box;
            }

            &:hover {
                & > span,
                & > a {
                    color: $menuColourActive;
                }

                // Sub Items handler
                .sub-items {
                    display: block;
                }
            }
        }

        .has-sub-items {
            @include display-flex();
            flex-flow: column;
            padding: 5px 0;

            @media #{$size-large} {
                padding: 0;
            }

            .sub-title {
                @include display-flex();
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                &::after {
                    content: '';
                    position: relative;
                    top: -5px;
                    background: url('/images/sort_desc.png') no-repeat;
                    padding: 10px 8px;

                }
            }
        }

        .sub-items {
            padding: 0;
            margin: 0;
            min-width: 100%;
            width: max-content;
            background: $menuLightColour;
            border: none;

            &.hide {
                display: none !important;
            }

            @media #{$size-medium} {
                position: absolute;
                top: 100%;
                left: 0;
                border: solid 1px $menuBorderColour;
                border-top: none;
            }

            li {
                font-size: 1.0em;
                font-weight: 400;
                text-align: center;

                &:hover {
                    background: $menuBorderColour;
                }
            }
        }
    }
}