$darkColour: #231F20;
$mainColour: #c9dee9; //Powder Blue
$accColour: #FEC600;
$menuColour: #58585a; //Charcoal
$menuBorderColour: #e7e7e8; //Silver
$menuFontColour: #687d90; //Slate
$menuColourActive: #019fcb; //Sky Blue

$menuActionColour: #0c3c7c; //Royal Blue
$menuWarnColour:  #c10042; //Cranberry

$tableRowActiveColour: #c6bcd0; //Lavender

$menuLightColour: mix($menuBorderColour, white, 50);

$successColour: #1daf42;