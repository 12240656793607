.draggable {
    position: relative;
    .template {
        display: none !important;
    }

    .current {
        position: absolute;
        left: 10px;
        z-index: 80;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        pointer-events: none;

        input { background: #cfcfcf; }
    }

    .draggable__spacer {
        width: 150px;
        margin: 5px 0;
        border-bottom: solid grey 1px;
    }
}