@import 'mixins';
@import 'fonts';
@import 'globals';
@import 'colours';

.footer {
    @include display-flex();
    justify-content: center;
    align-items: flex-end;
    color: $menuFontColour;

    &__content {
        position: relative;
        @include display-flex();
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        background: mix($menuBorderColour, white, 50);
        width: 100%;
        padding: 5px 10px;
        border-top: solid 1px $menuBorderColour;
        box-sizing: border-box;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: white;
            height: 1px;
        }

        .copy {
            color: $menuActionColour;
            font-weight: 700;
        }

        .image {
            max-height: 35px;
            margin-right: 15px;

            img {
                max-height: inherit;
            }
        }

        .content {
            @include display-flex();
            flex-flow: column;
        }

        .message {
            width: 100%;
            padding-top: 5px;
            margin-top: 5px;
            font-size: 0.8em;
            text-align: center;
            border-top: solid 1px $menuBorderColour;
        }

    }

}