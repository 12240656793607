@import 'mixins';
@import 'fonts';
@import 'colours';

$size-medium: "screen and (min-width: 426px)";
$size-large: "screen and (min-width: 769px)";
$size-wide: "screen and (min-width: 1025px)";


body.loadable {
    max-height: 100vh;
    overflow: hidden;
}

.wrapper {
    flex: 1;
    @include display-flex();
    flex-flow: column;
    min-height: 100vh;
}

.content-wrapper {
    position: relative;
    flex: 1;
    @include display-flex();
    flex-flow: column;

    &--hor {
        @media #{$size-large} {
            flex-flow: row;
        }
    }

    & > .content {
        max-width: 1600px;
        margin: 0 auto;
    }
}

%max-content {
    max-width: 1200px;
    margin: 0 auto;
}

// Reusable components
.main-title {
    padding: 4px 10px;
    margin: 10px auto;
    color: $menuActionColour;

    @media #{$size-large} {
        margin: 10px;
    }
}

.style-button {
    background: $menuWarnColour;
    color: white;
    width: 100%;
    padding: 8px 15px;
    margin: 10px 0;
    font-size: 1.1em;
    font-weight: 700;
    border: solid 1px $menuWarnColour;
    border-radius: 0;
    transition: all ease 0.3s;
    box-sizing: border-box;
    cursor: pointer;

    a {
        color: white;
        text-decoration: none;
        transition: all ease 0.3s;
    }

    &:hover:not(.disabled),
    &:active:not(.disabled),
    &:active:not(.disabled):hover:not(.disabled),
    &:focus:not(.disabled) {
        background: white !important;
        color: $menuWarnColour;
        border-color: $menuWarnColour;

        a {
            color: $menuWarnColour;
        }
    }

    &--alt {
        background: $menuActionColour;
        border-color: $menuActionColour;
        
        &:hover:not(.disabled),
        &:active:not(.disabled),
        &:active:not(.disabled):hover:not(.disabled),
        &:focus:not(.disabled) {
            background: white !important;
            color: $menuActionColour;
            border-color: $menuActionColour;

            a {
                color: $menuActionColour;
            }
        }
    }


    &--disabled {
        $disableColour: mix($menuBorderColour, black, 80);
        background: $disableColour;
        border-color: $disableColour;
        color: mix($disableColour, white, 30);
        pointer-events: none;
        user-select: none;

        &:hover {
            background: $disableColour;
            border-color: $disableColour;
        }

    }



    @media #{$size-large} {
        width: unset;
    }
}

.file-group {
    input[type=file] {
        display: none;
    }
}

.block {
    padding: 0 10px;
    box-sizing: border-box;

    &__group {
        background: $menuLightColour;
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        box-sizing: border-box;

        // @media #{$size-large} {
        //     width: max-content;
        //     width: -moz-max-content;
        // }

        &__title {
            font-size: 1.2em;
        }
    }
}



// clear
a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %font-general;
}


input {
    border: solid 1px $mainColour;
}

input,
select,
textarea {
    @extend %font-general;
}

input[type=submit] {
    outline: none;
    user-select: none;
}