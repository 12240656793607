//mixins
@mixin display-flex($isImportant:false) {
    @if $isImportant {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
    }
    @else {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }    
}

//sizes
$size-medium: "screen and (min-width: 426px)";
$size-large: "screen and (min-width: 769px)";
$size-wide: "screen and (min-width: 1025px)";

//colours
$borderColour: #92640f;
$headerColour: #b67d12;
$cellColour: #f1f1f5;
$cellOddColour: #d7d7df;
$cellHighlightColour: #9dbdd3;
$cellSelectedColour: #d3a09d;
$buttonColour: #854931;
$inputOutline: #c9dee9;

$popupBackgroundColour: rgba(26, 26, 26, 0.85);
$popupColour: #f1f1f5;
$popupHeaderColour: #d4b375;

$maxWidth: 1600px;


// ROTARY SPECIFIC
@import 'globals';
@import 'mixins';
@import 'fonts';
@import 'colours';

$popupBackgroundColour: rgba(255, 255, 255, 0.4);
$headerColour: $menuActionColour;
$borderColour: mix($menuActionColour, black, 60);
$buttonColour: $menuWarnColour;
$cellSelectedColour: $menuColourActive;
$popupHeaderColour: $menuActionColour;
// /ROTARY SPECIFIC


%flex-button {
    align-self: center;
    background: $headerColour;
    min-width: 150px;
    width: max-content;
    padding: 10px;
    margin: 10px;
    color: white;
    font-size: 1.1em;
    font-weight: 700;
    border: solid 1px $headerColour;
    transition: all ease 0.3s;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        background: white;
        color: $headerColour;
    }
}




#flex-table {
    @include display-flex();
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;

    @media #{$size-medium} {
        align-items: flex-start;
        max-width: 1600px;
    }
}

//styling
.flex-table {
    display: grid;
    grid-column-gap: 2px;
    width: 100%;
    max-width: $maxWidth;
    padding: 10px;
    overflow: auto;
    border: solid 2px $borderColour;
    box-sizing: border-box;
    outline: solid 1px grey;

    @media #{$size-wide} {
        padding: 0;
    }

    &__empty {
        background: #eee;
        padding: 10px 15px;
        text-align: center;
        box-sizing: border-box;

        &.hide {
            display: none;
        }
    }

    &__header {
        position: relative;
        @include display-flex();
        justify-content: center;
        align-items: center;
        background: $headerColour;
        padding: 5px;
        min-width: 100px;
        color: white;
        font-size: 1.1em;
        text-align: center;
        cursor: pointer;

        &__title {
            pointer-events: none;
        }

        &__filter {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            color: white;
            font-size: 1.1em;
            border: none;
            outline: none;
            cursor: pointer;

            &[data-active=true] {
                color: red;
            }

            &.hide {
                display: none;
            }
        }

        &.hideCol {
            display: none;
        }
        
        //set sorting images
        &::after {
            content: '';
            background: url('/images/sort_both.png') no-repeat 0 0;
            padding: 5px;
            width: 5px;
            height: 10px;
            opacity: 0.6;
        }

        &[data-sort=asc] {
            //set sorting images
            &::after {
                content: '';
                background: url('/images/sort_asc.png') no-repeat 0 0;
                opacity: 1;
            }
        }
        &[data-sort=desc] {
            //set sorting images
            &::after {
                content: '';
                background: url('/images/sort_desc.png') no-repeat 0 0;
                opacity: 1;
            }
        }
    }

    &__cell {
        background: $cellColour;
        text-align: center;
        padding: 3px;
        min-width: 35px;
        min-height: 40px;
        max-height: 80px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: default;

        &.odd {
            background: $cellOddColour;
        }

        &.highlight {
            background: $cellHighlightColour;
        }
        &.selected {
            background: $cellSelectedColour;
        }

        &.hide,
        &.hideCol,
        &.hideBySearch,
        &[data-uid] {
            display: none;
        }
    }


    &__start,
    &__end {
        display: none;
    }


}

.flex-table-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    grid-gap: 3px;
    padding: 5px 10px;
    box-sizing: border-box;

    @media #{$size-medium} {
        grid-template-columns: repeat(4, 1fr);
    }

    @media #{$size-large} {
        @include display-flex();
        flex-flow: row wrap;
        margin: 0 auto;
    }

    &__button {
        background: $buttonColour;
        padding: 10px 15px;
        border: solid 1px $buttonColour;
        color: white;
        font-size: 1.1em;
        font-weight: 700;
        cursor: pointer;
        transition: all ease 0.3s;
        outline: none;

        @media #{$size-large} {
            margin-right: 5px;
            
            &:hover {
                background: white;
                color: $buttonColour;
            }
        }

        &.disabled {
            background: white;
            border: solid 1px grey;
            color: grey;
            pointer-events: none;
        }
    }

    //col-visibility button
    .colVis {
        position: relative;
        z-index: 3;

        & > button {
            width: 100%;
        }

        &__container {
            position: absolute;
            left: 0;
            right: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 3px;
            background: grey;
            min-width: calc(100% - 5px);
            padding: 3px;
            box-sizing: border-box;

            button {
                padding: 10px 5px;
                font-size: 1.1em;
                box-sizing: border-box;
                cursor: pointer;

                &[data-active=true] {
                    background: $buttonColour;
                    color: white;
                }

                &[data-active=false] {
                    background: white;
                    color: grey;
                }

            }

            &.hide {
                display: none;
            }
        }

    }

}


.flex-table-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include display-flex();
    justify-content: center;
    align-items: center;
    background: $popupBackgroundColour;
    z-index: 90;

    &.hide {
        display: none;
        pointer-events: none;
    }

    input:not([type=submit]),
    select,
    textarea {
        width: 100%;
        padding: 10px;
        margin: 0;
        box-sizing: border-box;
        border-color: $inputOutline;
    }
    input[type=checkbox] {
        width: max-content;
        margin-left: 5px;
        cursor: pointer;
    }
    input[type=radio] {
        -webkit-appearance: radio;
        opacity: 1;
        cursor: pointer;
    }
    textarea {
        resize: vertical;
        min-height: 50px;
        max-height: 120px;
    }



    &__container {
        @include display-flex();
        flex-flow: column;
        background: $popupColour;
        min-width: 300px;
        width: 100%;
        max-width: 100vw;
        min-height: 200px;

        @media #{$size-large} {
            max-width: 800px;
        }
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr 50px;
        align-items: center;
        background: $popupHeaderColour;
        font-size: 1.3em;
        color: white;
        font-weight: 700;

        &__title {
            padding: 15px 20px;
        }

        &__close {
            @include display-flex();
            justify-content: center;
            align-items: center;
            background: #b90000;
            height: 100%;
            padding: 5px;
            margin: 0;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background: mix(#b90000, black, 80);
            }
        }
    }

    &__body {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        max-height: 70vh;
        padding: 15px;
        overflow-y: auto;

        .flex-group {
            padding: 4px 5px;
            box-sizing: border-box;

            label {
                display: grid;
                grid-template-columns: 150px 1fr;
                align-items: center;

                b {
                    display: block;
                    align-self: flex-start;
                    height: 100%;
                    box-sizing: border-box;
                }
            }

            &__radio {
                b {
                    font-weight: 400;
                }
                input {
                    position: relative;
                    justify-self: flex-start;
                    width: unset;
                    text-align: left;
                }
            }
        }
    }


    .content {
        @include display-flex();
        flex-flow: row wrap;
        justify-content: center;
        font-size: 1.2em;

        .counter {
            padding: 0 5px;
            color: $headerColour;
        }
    }

    &__submit {
        @extend %flex-button;
    }

    //used for multi select
    input, 
    select, 
    textarea,
    .options {
        &.hide {
            display: none;
        }
    }


    .multi-availability {
        background: grey;
        padding: 5px 10px;
        border: solid 1px black;
        box-sizing: border-box;

        &.hide {
            display: none;
        }
    }

}


.flex-table-filter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include display-flex();
    justify-content: center;
    align-items: center;
    background: $popupBackgroundColour;
    z-index: 90;

    &.hide {
        display: none;
        pointer-events: none;
    }

    &__container {
        background: white;
        width: 100vw;

        @media #{$size-medium} {
            width: 350px;
        }
    }

    .title {
        @include display-flex();
        justify-content: center;
        align-items: center;
        background: $headerColour;
        width: 100%;
        min-height: 30px;
        padding: 5px 0;
        color: white;
        font-weight: 700;
        font-size: 1.3em;
        text-align: center;
    }

    .sort-types {
        @include display-flex();
        flex-flow: column;

        .sort-type {
            padding: 4px 10px;
            margin-bottom: 2px;
            box-sizing: border-box;
            cursor: pointer;
        }
    }

    .clear {
        background: $cellOddColour;
        margin: 4px 10px;
        padding: 8px 10px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
            color: grey;
        }
    }

    .search {
        padding: 4px 10px;
        box-sizing: border-box;

        input {
            width: 100%;
        }
    }

    .filter-cells {
        @include display-flex();
        flex-flow: column;
        max-height: 50vh;
        height: 300px;
        margin: 0 10px;
        overflow-y: auto;
        border: solid 1px grey;
        box-sizing: border-box;

        .filter-cell {
            flex-shrink: 0;
            @include display-flex();
            align-items: center;
            padding: 5px 0;
            padding-right: 5px;
            box-sizing: border-box;

            &:nth-child(even) {
                background: #eee;
            }

            &.hide {
                display: none;
                pointer-events: none;
            }
        }
    }

    .inject-cells {
        flex-shrink: 0;
    }

    .commands {
        @include display-flex();
        flex-flow: row wrap;
        justify-content: flex-end;

        button {
            @extend %flex-button;
            min-width: unset;
            padding: 8px 12px;
        }
    }
}



.flex-table-filter-button {
    @extend %flex-button;
    @include display-flex();
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0;
    font-size: 1.2em;
    box-sizing: border-box;

    &[data-active=true] {
        background: white;
        color: $buttonColour;
    }

    span {
        margin-left: 5px;
    }

    @media #{$size-medium} {
        width: unset;
    }
}

.flex-table-row-counter {
    @include display-flex();
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    color: grey;
    padding: 2px 4px;
    box-sizing: border-box;

    @media #{$size-medium} {
        justify-content: flex-start;
    }

    .total,
    .selected {
        margin: 2px 4px;
        box-sizing: border-box;

        &__counter {
            color: $buttonColour;
        }
    }
}

.result {
    padding: 10px;
    color: $buttonColour;
    font-size: 1.1em;
    text-align: center;

    &.warn { color: red; }
}


.flex-table-search {
    align-self: flex-end;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    @media #{$size-large} {
        width: 50vw;
        align-self: flex-end;
    }
    @media #{$size-wide} {
        width: 35vw;
    }

    input {
        width: 100%;
    }
}




// ROTARY SPECIFIC
.flex-table {
    @extend %font-general;
    grid-gap: 0;
    border: none;
    outline: solid 1px $menuBorderColour;

    &__empty {
        font-size: 1.3em;
        color: $menuActionColour;
    }

    &__header {
        background: $menuLightColour;
        color: $menuFontColour;
        font-weight: 700;
        border-bottom: solid 1px $menuBorderColour;
        

        &__filter {
            color: $menuColour;

            &.active {
                color: $menuWarnColour;
            }
        }

        &:hover {
            color: $menuColourActive;
        }
    }

    

    &__cell {
        background: white;
        border-bottom: solid 1px $menuLightColour;
        color: $menuColour;

        &.odd {
            background: white;
        }

        &.highlight {
            background: $cellHighlightColour;
        }
        &.selected {
            background: $cellSelectedColour;
            color: white;
        }
    }
}

.flex-table-popup {
    &__container {
        border: solid 1px $menuActionColour;
    }

    .content .counter {
        color: $menuWarnColour;
    }
    .multi-availability {
        background: mix($menuColour, white, 20);
        border-color: mix($menuBorderColour, black, 80);
        color: $menuColour;
    }
}

.flex-table-buttons {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: white;
    width: 100%;
    border-bottom: solid 1px $menuBorderColour;
    z-index: 80;

    & > * {
        background: white;
        color: $menuFontColour;
        border: none;
        border-right: solid 1px $menuBorderColour;

        &.disabled {
            border: none;
            color: mix($menuFontColour, white, 40);
            border-right: solid 1px $menuBorderColour;
        }

        &:nth-child(even) {
            border: none;
        }

        &:last-child {
            border: none;
        }

        @media #{$size-medium} {
            border-right: solid 1px $menuBorderColour;
            &:nth-child(even) {
                border-right: solid 1px $menuBorderColour;
            }
    
            &:nth-child(4n) {
                border: none;
            }
        }
        @media #{$size-large} {
            &:nth-child(even),
            &:nth-child(4n) {
                border-right: solid 1px $menuBorderColour;
            }
        }
        
    }
}

.colVis button {
    background: white;
    color: inherit;
    border: none;
}


.flex-table-filter {
    &__container {
        border: solid 1px $menuActionColour;

        .filter-cell {
            &[data-action=select-all],
            &[data-action=blanks] {
                background: mix($menuActionColour, white, 10);
            }
        }
    }
}

.flex-table-row-counter {
    .selected,
    .total {
        &__counter {
            color: $menuColourActive;
        }
    }
}

.filter-bar {

    @media #{$size-medium} {
        align-self: flex-end;
    }
}

textarea {
    @extend %font-general;
}

button[data-type=print], 
button[data-type=excel], 
button[data-type=pdf] {
    color: $menuActionColour;

    &:hover {
        color: $menuWarnColour;
    }
}
// /ROTARY SPECIFIC
